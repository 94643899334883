const en = {
  accessoryUnavailable: "Accessory(ies) not available",
  activity: "Activity",
  add: "Add",
  addDocuments: "Add documents",
  addEquipmentInformation: "Add equipment information",
  addEvent: "Add event",
  addFile: "Add file",
  addInformation: "Add information",
  additionalInformation: "Additional information",
  addMessageToCustomer: "Message to the customer",
  addNewDocument: "Add new document",
  addResource: "Add a resource",
  address: "Address",
  alreadyCustomer: "Already customer ?",
  analytics: "Analytics",
  anErrorOccurred: "An error occurred",
  apiStatus: "API Status",
  applicationVersion: "Application Version",
  areYouSureYouWantToDelete: "Are you sure you want to delete ?",
  areYouSureYouWantToDeleteWithItem: "Are you sure you want to delete {{item}} ?",
  article: "Article",
  articleAccess: "Article access",
  articleEquipmentUpdated: "Article equipment updated",
  articleInformation: {
    message: "For proper customer use, add this information before the start of the service.",
    warningDescription: "Missing equipment information Provide required documents and information before the rental starts.",
    warningTitle: "Missing equipment information",
  },
  articleNameIsRequired: "The article name is required",
  articles: "Articles",
  assignment: "Assignment",
  associatedMachine: "Associated machine",
  availability: "Availability",
  available: "Available",
  backToLogin: "Back to login",
  beginning: "Beginning",
  between: "between",
  billing: "Billing",
  billings: "Billings",
  bookingDate: "{{fromDate}} to {{toDate}}",
  bookingEnd: "Booking end",
  bookings: "Bookings",
  bookingStart: "Booking start",
  bookingStartTomorrow: "The booking start tomorrow",
  bookingStartTomorrowMessage:
    "Make sure everything is ready for delivery, respecting the customer's constraints and including requested accessories if applicable.",
  brand: "Brand",
  brandIsRequired: "The brand is required",
  breadcrumbs: {
    booking: {
      requests: "n°{{id}}",
    },
  },
  by: "by",
  byFile: "by file",
  cancel: "Cancel",
  canceled: "Canceled",
  category: "Category",
  categoryName: "Category name",
  ceCertificate: "CE certificate",
  changePasswordAccount: "Change your Tracktor account password",
  clickToUpload: "Click to upload",
  client: "Client",
  closed: "Closed",
  closedAtIncidentAlert: "Closed at {{date}} by {{by}}",
  closeTheIncident: "Close the incident",
  code: "Code",
  codeOrKeysLocation: "Code or location of the keys",
  company: "Company",
  completed: "Completed",
  confirm: "Confirm",
  confirmed: "Confirmed",
  contacts: "Contacts",
  countryCode: "Country code",
  createAPasswordForYourAccount: "Create a password for your account",
  currentApiVersion: "Current API version",
  currentApiVersionUsed: "Current API version used",
  darkMode: "Dark mode",
  darkTheme: "Dark theme",
  dashboard: "Dashboard",
  dataSheet: "Data sheet",
  dates: "Dates",
  dayCount_one: "{{count}} Day",
  dayCount_other: "{{count}} Days",
  deals: "Deals",
  declaredByUserAt: "Declared by {{user}} at {{date}}",
  delete: "Delete",
  deleteBookingFileMessage: "Are you sure you want to delete this file from the booking?",
  deleteFile: "Delete file",
  deleteFileConfirmation: "Deleting the file is irreversible. Are you sure you want to continue?",
  deleteResource: "Delete resource",
  deleteResourceConfirmation:
    "Deleting a resource is irreversible and will delete all its linked files. Are you sure you want to continue?",
  delivery: "Delivery",
  deliveryAndReturn: "Delivery and Return",
  deliveryTimeConstraint: "Delivery time constraint",
  document: "document",
  documentAdded: "Document added",
  documentDeleted: "Document deleted",
  documentName: "Document name",
  documents: "Documents",
  documentType: "Document type",
  documentUpdated: "Document updated",
  easilyManageYourRequests: "Easily manage your requests, track orders, and optimize your activities with ease.",
  edit: "Edit",
  editInfoDocument: "Edit document information",
  editMyPassword: "Edit my password",
  editResource: "Edit resource",
  emailSent: "Email sent",
  emailSentForgotPassword: "An email has been sent to reset your password.",
  emptyBooking: "You have no upcoming booking",
  emptyBookingMissing: "You have no missed requests",
  end: "End",
  enterTheCode: "Enter the code",
  enterYourCode: "Enter your code",
  enterYourMessage: "Enter your message",
  equipment: "Equipment",
  equipmentInformation: {
    maximumPlates: "Maximum load on the skid (T)",
    sizePlates: "Size of distribution plates (m²)",
    skid: "Maximum pressure under the distribution plate (T/m²)",
  },
  equipmentNotOffered: "Equipment not offered",
  error: {
    unknownError: "Unknown error, please try again",
  },
  error404: "Error 404",
  eventStatus: {
    accepted: "Rental request accepted",
    canceled: "Rental request canceled",
    refused: "Rental request refused",
    sent: "Rental request received",
  },
  expirationDate: "Expiration date",
  expired: "Expired",
  file: "the document",
  fileAddedSuccessfully: "File added successfully",
  fileDeletedSuccessfully: "File deleted successfully",
  fileHasBeenUploaded: "The file has been uploaded",
  fileName: "File name",
  files: "Files",
  fileStatus: {
    expired: "Expired",
    expires_soon: "Expires soon",
    up_to_date: "Up to date",
  },
  fileType: "File type",
  finishDayCount: "This term ends in {{count}} days",
  finishToday: "Finish today",
  finishTomorrow: "Finish tomorrow",
  firstName: "Firstname",
  flatRate: "Flat Rate",
  forgottenPassword: "Forgotten password",
  forgottenPasswordSubtitle: "Enter your email address. We will send you a link to reset your password",
  form: {
    constraint: {
      password: "The password must contain at least 8 characters and a capital letter",
    },
  },
  generalTerms: "General terms",
  globalSearchPlaceholder: "Enter a deal ID or article name",
  happyToSeeYouAgain: "Happy to see you again !",
  help: "Help",
  helperText: {
    onlyImageFilesAreAllowed: "Only .png, .jpeg, .pdf files are allowed",
  },
  home: "Home",
  icon: "Icon",
  identity: "Identity",
  identityCard: "Identity card",
  incidentAddedSuccessfully: "Incident added successfully",
  incidentClosed: "Incident closed",
  incidentOpened: "Incident opened",
  indicateCodeOrKeysLocation: "Indicate the code or location of the keys",
  information: "Information",
  informations: "Informations",
  inProgress: "In progress",
  instructionManual: "Instruction manual",
  insurance: "Insurance",
  internalCodeIsRequired: "The internal code is required",
  internalReference: "Internal Reference",
  invoice: "Invoice",
  irreversibleAction: "This action is irreversible",
  key: "Key",
  keyLocation: "Key location",
  keys: "Keys",
  language: "Language",
  lastName: "Lastname",
  linkDocuments: "Link documents",
  linkDocumentsToBooking: "Client-visible documents",
  list: "List",
  login: "Login",
  logout: "Logout",
  machineRegistration: "Machine registration",
  mail: "Mail",
  maintenanceLog: "Maintenance log",
  makeAnOffer: "Make an offer",
  material: "Material",
  materialDropOffPoint: "Material drop-off point",
  messageToCustomer: "Message to the customer",
  missed: "Missed",
  newDate: "New date",
  newDateProposal: "Available from another close date",
  newDocument: "New document",
  newPassword: "New password",
  newPasswordConfirm: "Confirm new password",
  nextAssignment: "Next assignment",
  no: "No",
  noAvailability: "No availability and impossible downgrade",
  noDocumentsFound: "No documents found",
  noEvents: "No events",
  noNameFound: "No name found",
  noOrderFound: "No order linked to this article has been found",
  noResourcesFound: "No resources found",
  notAvailable: "Not available",
  notes: "Notes",
  notFound: "The page you are looking for cannot be found.",
  notFoundRedirection: "Please check the URL or go back to the previous page.",
  num: "N°",
  open: "Open",
  operatorDocument: "Operator document",
  order: "Order",
  orderHistory: "Order history",
  orders: "Orders",
  orderSummary: "Order summary",
  other: "Other",
  oups: "Oups !",
  overview: "Overview",
  pageNotFound: "Page not found",
  password: "Password",
  passwordSetSuccessfully: "Your password has been updated",
  passwordsNotMatching: "Passwords do not match",
  passwordUpdated: "Your password has been updated",
  payments: "Payments",
  periodicGeneralInspection: "Periodic general inspection (PGI)",
  phone: "Phone",
  planning: "Planning",
  pleaseSetAPassword: "Please set a password",
  profile: "My profile",
  profileUpdated: "Your profile has been updated",
  purchaseOrder: "Purchase Order",
  purchaseOrderNumber: "Purchase order number",
  quantity: "Quantity",
  reason: "Reason",
  refused: "Refused",
  refusedBookingRequestMessage: "In confirming the rejection of this booking request, you will not be able to accept it later.",
  refusedTheBooking: "Refused the order",
  reload: "Reload",
  rentalRequests: "Requests",
  reopenTheIncident: "Reopen the incident",
  requests: "Requests",
  requestStatus: {
    expired: "Expired",
    proposed: "Proposed",
    refused: "Refused",
    scheduled: "To be processed",
    sent: "Sent",
  },
  requestWithID: "Requests n°{{id}}",
  resetInitialRequest: "Reset initial request",
  resetPassword: "Reset password",
  resource: "Resource",
  resourceCreated: "Resource created",
  resourceName: "Resource name",
  resourcePhoto: "Resource photo",
  resources: "Resources",
  resourceUpdated: "Resource updated",
  respondToDemand: "Respond to the demand",
  retrieval: "Retrieval",
  returnTo: "Return to",
  save: "Save",
  saveChanges: "Save Changes",
  saveTimeManagingYourOrders: "Save time managing your orders !",
  search: "Search",
  see: "See",
  seeDetail: "See detail",
  seeOffer: "See offer",
  selectDocumentType: "Select document type",
  selectExpirationDate: "Select expiration date",
  selectResource: "Select ressource",
  selectResourceWarning: "By selecting this resource, you confirm that it meets the client's needs.",
  sendOffer: "Send offer",
  sent: "Sent",
  serialNumber: "Serial number",
  settings: "Paramètres",
  startDayCount: "Starts in {{count}} days",
  startToday: "Start today",
  startTomorrow: "Start tomorrow",
  status: "Status",
  stayConnected: "Stay connected",
  supplierComment: "Supplier comment",
  TECHNICAL_SHEET: "Technical sheet",
  theBookingRequestHasBeenAccepted: "Your offer has been sent",
  theBookingRequestHasBeenRefused: "The booking request has been refused",
  to_add: "To add",
  toAdd: "To add",
  toCome: "To come",
  today: "Today",
  toDo: "To do",
  tokenVerification: "Token verification",
  total: "Total",
  totalHT: "Total HT",
  toUpdate: "To update",
  typeToSearch: "Type to search",
  units: {
    DAYS_one: "{{count}} Day",
    DAYS_other: "{{count}} Days",
    HOURS_one: "{{count}} Hour",
    HOURS_other: "{{count}} Hours",
    KM: "{{count}} Km",
    null: "{{count}} ",
  },
  up_to_date: "Up to date",
  uploadFile: "Upload file",
  upToDate: "Up to date",
  usedBy: "Used By",
  validate: "Validate",
  worksiteAccessConstraints: "Worksite access constraints",
  yes: "Yes",
  yourOffer: "Your offer",
  yourOrdersAreWaitingForYouLogin: "Your orders are waiting for you, log in !",
} as const;

export default en;
