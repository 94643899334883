import * as Sentry from "@sentry/react";
import { GTMSendPageView, handleImportModuleError, RequireAuth } from "@tracktor/shared-module";
import { lazy, Suspense } from "react";
import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, RouterProvider } from "react-router-dom";
import ErrorFallback from "@/components/FeedBack/ErrorFallback";
import PageLoading from "@/components/Layouts/PageLoading";
import ROUTES from "@/constants/routes";
import useAuth from "@/hooks/useUser";

const BackofficeContainer = lazy(() => import("@/features/BackofficeContainer"));
const DashBoard = lazy(() => import("@/pages/Dashboard"));
const NotFound = lazy(() => import("@/pages/NotFound"));
const Help = lazy(() => import("@/pages/Help"));
const Company = lazy(() => import("@/pages/Company"));
const Deal = lazy(() => import("@/pages/Deals/Deal"));
const Deals = lazy(() => import("@/pages/Deals"));
const Billings = lazy(() => import("@/pages/Billings"));
const Login = lazy(() => import("@/pages/Login"));
const ForgotPassword = lazy(() => import("@/pages/ForgotPassword"));
const Payments = lazy(() => import("@/pages/Payments"));
const Profile = lazy(() => import("@/pages/Profile"));
const Requests = lazy(() => import("@/pages/Requests"));
const Request = lazy(() => import("@/pages/Requests/Request"));
const Info = lazy(() => import("@/pages/Info"));
const Resources = lazy(() => import("@/pages/Resources"));
const Resource = lazy(() => import("@/pages/Resources/Resource"));
const Analytics = lazy(() => import("@/pages/Analytics"));
const QueryNotFound = lazy(() => import("@/pages/QueryNotFound"));

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <Sentry.ErrorBoundary fallback={<ErrorFallback fullPage />} onError={handleImportModuleError}>
          <GTMSendPageView />
        </Sentry.ErrorBoundary>
      }
    >
      <Route element={<BackofficeContainer />}>
        <Route
          element={
            <Sentry.ErrorBoundary fallback={<ErrorFallback />} onError={handleImportModuleError}>
              <RequireAuth isLogged={useAuth} Fallback={<PageLoading />} />
            </Sentry.ErrorBoundary>
          }
        >
          {/* Default logged route */}
          <Route path="/" element={<Navigate to={ROUTES.requests} />} />

          {/* Error request route */}
          <Route path={ROUTES.queryError} element={<QueryNotFound />} />

          {/* Deals */}
          <Route path={ROUTES.deals}>
            <Route index element={<Deals />} />
            <Route path=":id" element={<Deal />} />
          </Route>

          {/* Request */}
          <Route path={ROUTES.requests}>
            <Route index element={<Requests />} />
            <Route path={`${ROUTES.requests}/*`} element={<Request />}>
              <Route path=":id" element={<Request />} />
            </Route>
          </Route>

          {/* Resources */}
          <Route path={ROUTES.resources}>
            <Route index element={<Resources />} />
            <Route path={`${ROUTES.resources}/*`} element={<Resource />}>
              <Route path=":id" element={<Resource />} />
            </Route>
          </Route>

          {/* Analytics */}
          <Route path={ROUTES.analytics} element={<Analytics />} />

          <Route path={ROUTES.dashboard} element={<DashBoard />} />
          <Route path={ROUTES.billings} element={<Billings />} />
          <Route path={ROUTES.payments} element={<Payments />} />
          <Route path={ROUTES.company} element={<Company />} />
          <Route path={ROUTES.help} element={<Help />} />
          <Route path={ROUTES.profile} element={<Profile />} />
          <Route path={ROUTES.info} element={<Info />} />
        </Route>
      </Route>

      {/* Public route */}
      <Route
        element={
          <Suspense fallback={<PageLoading />}>
            <Outlet />
          </Suspense>
        }
      >
        <Route path={ROUTES.login}>
          <Route path="*" element={<Login />}>
            <Route index path="*" element={<Login />} />
            <Route path=":logout" element={<Login />} />
          </Route>
        </Route>
        <Route path={ROUTES.forgotPassword} element={<ForgotPassword />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Route>,
  ),
);

const Router = () => <RouterProvider router={router} />;

export default Router;
