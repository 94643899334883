export const REQUESTS_STATUS = {
  expired: "expired",
  proposed: "proposed",
  refused: "refused",
  sent: "sent",
} as const;

const ROUTES = {
  analytics: "/analytics",
  billings: "/billings",
  company: "/company",
  dashboard: "/dashboard",
  deals: "/deals",
  dealsId: (id: string | number) => `${ROUTES.deals}/${id}`,
  forgotPassword: "/forgot-password",
  help: "/help",
  info: "/info",
  login: "/login",
  payments: "/payments",
  profile: "/profile",
  queryError: "/query-error",
  requests: "/requests",
  requestsTabId: (requestId: string | number) => `${ROUTES.requests}/${requestId}`,
  resource: (id: string | number) => `${ROUTES.resources}/${id}`,
  resources: "/resources",
} as const;

export default ROUTES;
